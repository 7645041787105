#github, #behance, #instagram, #linkedin{
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}

#github:hover, #behance:hover, #instagram:hover, #linkedin:hover{
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  cursor: pointer;
  transition: 0.3s;
}
