.section__Jobs-buttonCompany {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  height: 50px;
  width: 100%;
  text-align: left;
  padding: 0 20px;
  cursor: pointer;
}

.section__Jobs-buttonCompany:hover,
.section__Jobs-buttonCompany:active,
.section__Jobs-buttonCompany:focus {
  outline: 0px;
}
