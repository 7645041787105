
.experience {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  min-height: 70vh;
}
.containerExp {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin: 30px 0;
}
.col-sm-3 {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
}
.col-sm-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.experience .caption {
  font-size: 0.8rem;
  font-weight: 300;
  color: #858585;
}
.experience .company {
  font-size: 0.8rem;
  font-weight: 500;
  color: #FF960C;
}
.experience .headerStep {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.containerExp li {
  list-style-type: disc;
}
.section__Jobs-styledTabList {
  padding: 0;
}
#companyMarker {
  border-left: 2px solid#FF960C;
  font-size: 1rem;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 15px;
  display: inline-block;
  width: 2px;
  height: 50px;
  transition: transform 0.5s;
}
#companyMarker.company-gruporaotes-chosen {
  transform: translateY(0px);
}
#companyMarker.company-empresa2-chosen {
  transform: translateY(50px);
}
#companyMarker.company-empresa3-chosen {
  transform: translateY(100px);
}
