nav {
  z-index: 20;
  position: fixed;
  width: 100vw;
  height: fit-content;

  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 5px );
  -webkit-backdrop-filter: blur( 5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
nav ul {
  color: #F3F3F3;
  font-weight: 600;
}
.icons a:-webkit-any-link{
  color: transparent;
}
#logo{
  margin-top: -6px;
}
.algin-center-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.text-nav li a{
  color: #F3F3F3;
  display: inline-block;
  position: relative;

  margin: 15px;
  font-size: 1.1rem;

  cursor: pointer;
  transition: 0.5s;
}
/* effect-underline */
.text-nav li a:after {
	content: '';
  position: absolute;
  display: inline-block;
  left: 0;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0,1);
	transform: scale(0,1);
}
.text-nav li a:hover:after {
  opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
@media (max-width: 768px){
  .text-nav li a{
    font-size: 0.9rem;
  }
  #logo{
    width: 7rem;
    height: 7rem;
  }
  .algin-center-nav{
    gap: 10px;
  }
}
@media (max-width: 426px){
    .text-nav li a{
        font-size: 0.8rem;
    }
    .algin-center-nav{
        gap: 15px;
    }
    .iconsMobile{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
    }
    .iconsMobile svg{
        width: 2rem;
    }
    #logo{
        width : 5.3rem;
        height: 5.3rem;
    }
    nav{
        bottom: 0;
    }
}
