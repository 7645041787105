.frase-imagens{
  background: linear-gradient(to right, #E0830A , #FF960C);
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.frase-imagens h1 {
  font-size: 7rem;
  text-align: center;
  font-weight: 700;

  width: 50%;
  color: #C37815;
}
.frase-imagens span {
  color: #FEE7C9;
}
.intro{height: fit-content;}
#developer{margin-top: 140px;}
#designer{margin-bottom: 140px;}
#developer, #designer{
  font-size: 12.5rem;
  width: 100%;
  height: fit-content;
  text-transform: uppercase;
}
#developer      {color: #C37815;}
#designer       {color: #E3AA5F;}
#developer span {color: #E3AA5F;}
#designer  span {color: #C37815;}

.frase-imagens button {
  margin-top: 30px;
  padding: 10px 40px;

  background-color: #FEE7C9;
  border: 2.3px solid #FEE7C9;
  border-radius: 5px;
  color: #E0830A;

  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;

  transition: 0.3s;
}
.frase-imagens button:hover {
  background-color: transparent;
  border: 2.3px solid #FEE7C9;
  border-radius: 5px;
  color: #FEE7C9;

  transition: 0.3s;
}

/* Laptop */
@media(max-width: 1024px){
  .frase-imagens h1 {
    font-size: 5.5rem;
  }
  #developer, #designer{
    font-size: 8.2rem;
  }
}
/* Tablet */
@media(max-width: 768px){
  .frase-imagens h1 {
    font-size: 4.5rem;
    width: 60%;
  }
  #developer, #designer{
    font-size: 6.8rem;
    height: 20%;
  }
}

/* Mobile */
@media(max-width: 426px){
  .frase-imagens{
    height: 125vh;
  }
  .frase-imagens h1 {
    font-size: 3.5rem;
    width: 80%;
  }
  .frase-imagens button{
    font-size: 1.1rem;
    padding: 10px 20px;
    margin: 20px 0 0 0;
  }

  .intro{
    height: 100vh;

    margin: -30px 0 0 0;
    display: flex;
    flex-direction: column;
  }
  #developer, #designer{
    font-size: 4.3rem;
    height: 30%;
    font-weight: 800;
  }
  #developer span, #designer span{
    display: flex;
    flex-direction: column;
  }
  #developer span {color: #C37815;}
  #designer  span {color: #E3AA5F;}
}
@media(max-width: 360px){ #developer, #designer{font-size: 3.5rem;}}
