.step {
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  background-color: cream;
}

.v-stepper {
  position: relative;
}


.step .circle {
  background-color: white;
  border: 3px solid gray;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.step .line {
  top: 23px;
  left: 9px;
  height: 100%;
  position: absolute;
  border-left: 3px soligdayray;
}

.step.completed .circle {
  visibility: visible;
  background-color: #FF960C;
  border-color: #FF960C;
}

.step.completed .line {
  border-left: 3px solid #FF960C;
}

.step.active .circle {
  visibility: visible;
  border-color: #FF960C;
}

.step.empty .circle {
    visibility: hidden;
}

.step.empty .line {
  top: 0;
  height: 150%;
}


.step:last-child .line {
  border-left: 3px solid white;
  z-index: -1;
}

.content {
  margin-left: 20px;
  display: inline-block;
}
