.section__Jobs-detail {
  text-align: justify;
}

.section__Jobs--bullet::before {
  content: "•";
  position: absolute;
  left: 50px;
  color: #FF960C;
}

.section__Jobs-styledContent .caption {
  font-size: 0.8rem;
  font-weight: 300;
  color: #858585;
}
.section__Jobs-styledContent .company {
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(6,150,215);
}
.section__Jobs-styledContent .headerStep {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section__Jobs-styledContent .containerExp li {
  list-style-type: disc;
}


